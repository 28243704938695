.badge {
  font-size: 13px;
  margin: 5px;
  font-weight: bolder;
  @include themify($themes) {
    color: themed('colorHover');
  }
}

.bg-secondary {
  @include themify($themes) {
    background-color: themed('colorFinppi') !important;
    color: themed('colorBackgroundBody') !important;
  }
}
