.container-form {
  padding: 1rem 2rem 1rem 1rem;
}

.container-products {
  height: 80vh;
  overflow-y: auto;
}

.text-align-right {
  float: right;
}
.text-align-center {
  text-align: center !important;
}
.text-align-left {
  text-align: left !important;
}

.wrapper-cards {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 30vh;
  padding: 0.5rem;
  @include themify($themes) {
    background-color: themed('colorBackgroundBody');
  }
}

.container-card-product-form {
  margin: 5px 0 5px 0;
  padding: 0.5rem 0 0.5rem 0.5rem;
  border-radius: 4px;
  box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.1);
  @include themify($themes) {
    background-color: themed('colorBackgroundBody');
  }
}

.card-data-container {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 4px;
  z-index: 1;
  box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.1);
  @include themify($themes) {
    background-color: themed('colorBackgroundBody');
  }
}

.textarea-description {
  width: 100%;
  resize: none;
  border-radius: 4px;
  padding: 1rem;
}

.wrapper-cards::-webkit-scrollbar,
.container-form::-webkit-scrollbar,
.container-products::-webkit-scrollbar {
  width: 8px;
}

.wrapper-cards::-webkit-scrollbar-thumb,
.container-form::-webkit-scrollbar-thumb,
.container-products::-webkit-scrollbar-thumb {
  background: $color-gray;
  border-radius: 4px;
}

.wrapper-cards::-webkit-scrollbar-thumb:active,
.container-form::-webkit-scrollbar-thumb:active,
.container-products::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.wrapper-cards::-webkit-scrollbar-thumb:hover,
.container-form::-webkit-scrollbar-thumb:hover,
.container-products::-webkit-scrollbar-thumb:hover {
  background: $color-gray;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.wrapper-cards::-webkit-scrollbar-track,
.container-form::-webkit-scrollbar-track,
.container-products::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.wrapper-cards::-webkit-scrollbar-track:hover,
.wrapper-cards::-webkit-scrollbar-track:active,
.container-form::-webkit-scrollbar-track:hover,
.container-form::-webkit-scrollbar-track:active,
.container-products::-webkit-scrollbar-track:hover,
.container-products::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.pos__card-widget {
  padding-top: 25px;
  padding-bottom: 25px;
  overflow: hidden;
}

.pos-app-widget__title {
  @include directify($directions) {
    text-align: directed('left');
  }
  text-transform: uppercase;
  position: relative;
  margin: 0 0 20px;

  h5 {
    font-size: 12px;
    color: $color-gray;
    font-weight: 500;
  }
}
