.login-modal-form {
  .modal-content {
    padding-top: 20px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.modal-pos {
  .header {
    padding: 1rem 2rem 1rem 3rem;
    width: 100%;
    border-radius: 10px 10px 0 0;
    background-color: $color-accent;
    h4 {
      color: $color-white;
      text-align: left;
    }
  }
  .container {
    padding: 1rem;
  }

  .btn {
    &.btn-outline-secondary {
      @include themify($themes) {
        background-color: themed('colorFinppi');
        border-color: themed('colorFinppi');
      }

      svg {
        @include themify($themes) {
          fill: themed('colorText');
        }
      }

      &:before {
        @include themify($themes) {
          background-color: themed('colorFinppi');
        }
      }
      &:hover,
      &:focus,
      &:active,
      &:active:focus {
        background: transparent;
        border-color: $color-light-gray;
        color: $color-gray;

        p {
          color: $color-accent;
        }

        svg {
          fill: $color-accent;
        }
      }
    }
  }
}

.modal-dialog {
  text-align: center;
}

.modal-content {
  width: max-content;
  min-width: 50rem;
  border-radius: 12px;
  border: none;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
  @include themify($themes) {
    background-color: themed('colorBackground');
    color: themed('colorText');
  }
}

.modal__close-btn {
  position: absolute;
  top: 15px;

  @include directify($directions) {
    #{directed('right')}: 15px;
  }

  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;

  @include themify($themes) {
    color: themed('colorIcon');
  }
}

.modal__title-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.modal__title {
  margin-top: 10px;
  margin-bottom: 15px;

  &:first-child {
    margin-top: 0;
  }
}

.modal__footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
  }

  @include directify($directions) {
    flex-flow: directed('flex-flow') !important;
  }
}

.modal-dialog--primary {
  .modal__title-icon {
    color: $color-blue;
  }
}

.modal-dialog--success {
  .modal__title-icon {
    color: $color-accent;
  }
}

.modal-dialog--warning {
  .modal__title-icon {
    color: $color-yellow;
  }
}

.modal-dialog--danger {
  .modal__title-icon {
    color: $color-red;
  }
}

.modal-dialog--colored {
  color: $color-white;

  .modal__title-icon {
    color: $color-white;
  }

  .modal__close-btn {
    color: $color-white;
  }

  .modal__footer {
    button:first-child {
      color: $color-white;
      background-color: rgba(255, 255, 255, 0.3);
      border-color: $color-white;

      &:before {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    button:last-child {
      border-color: white;
      color: $color-white;
    }
  }

  &.modal-dialog--primary .modal-content {
    background-color: $color-accent;
  }

  &.modal-dialog--success .modal-content {
    background-color: $color-accent;
  }

  &.modal-dialog--warning .modal-content {
    background-color: $color-yellow;
  }

  &.modal-dialog--danger .modal-content {
    background-color: $color-red;
  }
}

.modal-dialog--header {
  max-width: 520px;

  .modal-content {
    padding: 0;

    @include directify($directions) {
      text-align: directed('left');
    }
  }

  .modal__header {
    color: $color-white;
    padding: 15px 20px;
    position: relative;
  }

  .modal__title {
    margin: 0;
    font-weight: 300;
  }

  .modal__close-btn {
    color: $color-white;
    top: calc(50% - 8px);
  }

  .modal__body {
    padding-top: 25px;
    padding-bottom: 20px;

    @include directify($directions) {
      #{directed('padding-right')}: 40px;
      #{directed('padding-left')}: 20px;
    }
  }

  .modal__footer {
    margin-bottom: 40px;
    justify-content: flex-end;

    @include directify($directions) {
      #{directed('padding-right')}: 20px;
    }
  }

  &.modal-dialog--primary .modal__header {
    background-color: $color-blue;
  }

  &.modal-dialog--success .modal__header {
    background-color: $color-accent;
  }

  &.modal-dialog--warning .modal__header {
    background-color: $color-yellow;
  }

  &.modal-dialog--danger .modal__header {
    background-color: $color-red;
  }
}

.modal-dialog--carousel {
  display: flex;
  align-items: center;
  max-width: 100%;
  height: 100%;
  user-select: none;
  -moz-user-select: none;

  .modal-content {
    display: flex;
    align-items: center;
    box-shadow: none;
    background: none;

    @include directify($directions) {
      text-align: directed('left');
    }
  }

  .modal__header {
    color: $color-white;
    height: 50px;
    text-align: right;
    padding: 10px 20px 10px 0;
    position: relative;
  }

  .modal__title {
    margin: 0;
  }

  .modal__close-btn {
    color: $color-white;
  }

  .modal__btn {
    padding: 0;
    cursor: pointer;
    color: transparentize($color-white, 0.5);

    &:hover:not([enabled]) {
      color: $color-white;
    }

    .modal__btn_left {
      width: 80px;
      height: 80px;
      position: absolute;
      left: 0;
    }

    .modal__btn_right {
      width: 80px;
      height: 80px;
      position: absolute;
      right: 0;
    }
  }

  .modal__body {
    max-width: 50%;
    overflow: hidden;

    li {
      align-items: stretch;
    }

    @include directify($directions) {
      #{directed('padding-right')}: 40px;
      #{directed('padding-left')}: 20px;
    }
  }

  .modal__footer {
    justify-content: flex-end;
    color: $color-white;
  }
}

@media screen and (max-width: 768px) {
  .modal-dialog--carousel {
    .modal__body {
      max-width: 100%;
    }

    .modal__btn {
      display: none;
    }
  }
}
