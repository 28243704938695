.card {
  width: 100%;
  padding-bottom: 30px;
  height: 100%;
  border: none;
  background-color: transparent;

  &.card--not-full-height {
    height: auto;
  }
}

.card-body {
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 50px;
  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  @include borderify($borders) {
    border-radius: bordered('borderRadius');
  }

  @include shadowify($shadows) {
    box-shadow: shadowed('blocksShadows');
  }
}

.card__title {
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;

  @include directify($directions) {
    text-align: directed('left');
  }

  h5.card__title-center {
    text-align: center;
  }

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.blocks-with-shadow-theme {
  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}

.card-animation {
  border-radius: 15px;
  padding: 10px 25px;
  margin-bottom: 20px;
  transition: all 0.4s;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  z-index: 0;

  @include directify($directions) {
    #{directed('margin-right')}: 15px;
  }

  &:last-child {
    margin-right: 0;

    @include directify($directions) {
      #{directed('margin-right')}: 0;
    }
  }

  &:before {
    position: absolute;
    height: 0;
    width: 0;
    border-radius: 50%;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    z-index: -1;
    content: '';
    top: 0;

    @include themify($themes) {
      background-color: themed('colorFinppi');
    }

    @include directify($directions) {
      transform: #{directed('translate')};
    }

    @include directify($directions) {
      #{directed('left')}: 0px;
    }
  }

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    outline: none;
    box-shadow: none !important;
    .div h5 {
      color: #fafbfe !important;
    }
    svg {
      fill: #fafbfe !important;
    }

    &:before {
      height: 500%;
      width: 225%;
    }
  }

  &:focus,
  &:active,
  &:active:focus {
    &:before {
      transition: all 0s;
    }
  }
}
