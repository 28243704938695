main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0 !important;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  line-height: 1.6;
}

* {
  box-sizing: inherit;
}

ul,
ol {
  // doesn't do RTL, it break a sidebar
  padding-left: 15px;
  margin-bottom: 0;
  list-style-image: url('../../shared/img/circle.svg');
}

a {
  color: $color-blue;
  transition: all 0.3s;
  text-decoration: none;
  &:hover {
    color: $color-blue-hover;
  }
}

img {
  width: 100%;
}
.mw-200 {
  min-width: 200px;
}

// Hide on mobile
@media screen and (max-width: 600px) {
  .hide-on-mobile {
    visibility: hidden;
    clear: both;
    display: none;
  }
}

.loading-rocket {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.error {
  &__container {
    display: grid;
    place-items: center;
    height: 100vh;
    width: 100%;
    padding: 4rem;
  }

  &__image {
    width: 300px;
  }

  &__text {
    font-size: 28px;
    color: #2f8e89;
  }
}
